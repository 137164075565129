import React, { Component } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import HorizontalNav from '../../Navigation/HorizontalNav'
import Logo from '../../UI/Logo'
import ContactCTA from '../../UI/ContactCTA'


/*==============================================================================
  # Style
==============================================================================*/

const DesktopContent = styled('div')`
  height: var(--header-height);
  opacity: 1;
  transition: all 450ms ${({theme}) => theme.easings.secondary};

  @media (max-width: 991px) {
    opacity: 0;
  }
`

const NavFlex = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BannerFlex = styled(NavFlex)`
  padding: 15px 0px;
`

const LogoWrapper = styled('div')`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
`

const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;

  .icon-box {
    ${({theme}) => theme.above.lg} {
      margin-right: 30px;
    }

    ${({theme}) => theme.above.xl} {
      margin-right: 45px;
    }
  }
`

const BrandLogo = styled(Img)`
  margin-left: 20px;
`

const MainNavigation = styled(HorizontalNav)`
  justify-content: center;
`


/*==============================================================================
  # Component
==============================================================================*/

class DesktopNav extends Component {

  render() {

    const { mainLinks, brandLogo } = this.props

    return (
		  <DesktopContent role="navigation">
        <BannerFlex>

          <LogoWrapper>
            <Logo/>
          </LogoWrapper>
          
          <ContentWrapper>
            <ContactCTA/>

            <a href="https://www.peugeot.se/" target="_blank" rel="noreferrer noopener">
              <BrandLogo 
                fixed={brandLogo.childImageSharp.fixed}
                alt="Peugeot"
              />
            </a>

          </ContentWrapper>

        </BannerFlex>
        <NavFlex>
          
          <MainNavigation links={mainLinks} />

        </NavFlex>
		  </DesktopContent>
    )
  }
}

export default DesktopNav
import React from 'react'

export const Mega = ({size = 'h2', children, className, ...rest}) => {
	const Tag = size
	let classNames = ['mega-size', className].join(' ');
	return <Tag className={classNames} {...rest} dangerouslySetInnerHTML={{__html: children }}/>
}

export const Hero = ({size = 'h2', children, className, ...rest}) => {
	const Tag = size
	let classNames = ['hero-size', className].join(' ');
	return <Tag className={classNames} {...rest} dangerouslySetInnerHTML={{__html: children }}/>
}

export const Heading = ({size = 'h3', children, className, ...rest}) => {
	const Tag = size
	let classNames = ['heading-size', className].join(' ');
	return <Tag className={classNames} {...rest} dangerouslySetInnerHTML={{__html: children }}/>
}

export const SubHeading = ({size = 'h3', children, className, ...rest}) => {
	const Tag = size
	let classNames = ['sub-heading-size', className].join(' ');
	return <Tag className={classNames} {...rest} dangerouslySetInnerHTML={{__html: children }}/>
}

export const SmallHeading = ({size = 'h4', children, className, ...rest}) => {
	const Tag = size
	let classNames = ['small-heading-size', className].join(' ');
	return <Tag className={classNames} {...rest} dangerouslySetInnerHTML={{__html: children }}/>
}

export const variableHeadingTag = tag => {
	const tags = {
    Mega,
    Hero,
    Heading,
    SubHeading,
    SmallHeading
  }
	return tags[tag]
}

export const getSize = (title_size) => {
  switch(title_size) {
    case 'small':
      return 'Heading'
    case 'medium':
      return 'Hero'
    default:
      return 'Mega'
  }
}
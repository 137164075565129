import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Link from '../Link'

/*==============================================================================
  # Styles
==============================================================================*/

const secondaryStyle = theme => css`
  color: ${theme.colors.black};
  background-color: ${theme.colors.white};

  &:hover {
  	background-color: ${theme.colors.grey};
  }
`;

const disabledStyle = () => css`
  opacity: 0.5;
  cursor: default;
`;

export const buttonStyle = ({ secondary, theme, disabled }) => css`
  display: inline-block;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  color: ${theme.colors.bg};
  background-color: ${theme.colors.primary};
  outline: 0;
  cursor: pointer;
  ${theme.fontSizes.description}
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  transition: all 250ms ${theme.easings.secondary};

  &:hover {
    text-decoration: none;
    color: ${theme.colors.black};
    background-color: ${theme.colors.grey};
    box-shadow: ${theme.styles.boxshadow};
  }

  ${secondary && secondaryStyle(theme)};
  ${disabled && disabledStyle(theme)};
`;

const BaseButtonLink = styled(Link)`
  ${buttonStyle}
`;

const Button = styled('button')`
  ${buttonStyle}
`;


/*==============================================================================
  # Components
==============================================================================*/

export const ButtonLink = ({ children, ...props }) => (
  <BaseButtonLink {...props}>
    {children}
  </BaseButtonLink>
)

const ButtonWithLoading = ({ loading, loadingText, children, ...props }) =>
  loading ? (
    <Button {...props}>
      {loadingText}
    </Button>
  ) : (
    <Button {...props}>
    	{children}
    </Button>
  )

export default ButtonWithLoading;
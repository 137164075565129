import React, { Component } from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Container, { Row, Col } from '../UI/Grid'
import ContactCTA from '../UI/ContactCTA'
import Logo from '../UI/Logo'


/*==============================================================================
  # Styles
==============================================================================*/

const FooterWrapper = styled('footer')`
  flex-shrink: 0;
  margin-top: 30px;
  margin-bottom: 15px;

  ${({theme}) => theme.above.md} {
    margin-bottom: 30px;
    margin-top: 50px;
  }

  ${({theme}) => theme.above.lg} {
    margin-bottom: 60px;
  }

  ${({theme}) => theme.above.xl} {
    margin-bottom: 100px;
  }
`

const FooterRow = styled(Row)`
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  
  ${({theme}) => theme.above.md} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${({theme}) => theme.above.lg} {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    background-color: ${({theme}) => theme.colors.blueDarker};
  }
`

const FirstCol = styled(Col)`
  ${({theme}) => theme.below.md} {
    text-align: center;
  }
`

const FooterLogo = styled(Logo)`

`

const FooterContactCTA = styled(ContactCTA)`
  margin-top: 30px;
  max-width: 500px;

  ${({theme}) => theme.below.sm} {
    flex-direction: column;

    .icon-box {
      margin-left: -30px;
    }
  }

  ${({theme}) => theme.below.md} {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
`

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin: 0px -10px;

  ${({theme}) => theme.below.md} {
    justify-content: center;
    margin-top: 30px;
  }

  ${({theme}) => theme.above.lg} {
    margin: 0px -30px;
  }
`

const Icon = styled(Img)`
  margin: 10px;

  ${({theme}) => theme.above.lg} {
    margin: 10px 30px;
  }
`


/*==============================================================================
  # Component
==============================================================================*/

class Footer extends Component {

  render() {

    return (
      <StaticQuery 
        query={graphql`
          query FooterQuery {
            ...Footer
          }
        `}
        render={data => {

          const { footer_icons } = data.allWordpressAcfOptions.edges[0].node.options

          return (
            <FooterWrapper>
              <Container>
                <FooterRow>

                  <Col col={12} md={10}>
                    <Row>

                      <FirstCol col={12} md={7}>
                        <FooterLogo white={true}/>
                        <FooterContactCTA white={true}/>
                      </FirstCol>

                      <Col col={12} md={5}>
                        <IconContainer>
                          {footer_icons && Array.isArray(footer_icons) && footer_icons.map((icon, i) => {
                            icon = icon.icon
                            if ( icon && icon.localFile ) {
                              return (
                                <Icon
                                  key={i}
                                  fixed={icon.localFile.childImageSharp.fixed}
                                  alt={icon.alt_text}
                                />
                              )
                            } else {                      
                              return null
                            }
                          })}
                        </IconContainer>
                      </Col>

                    </Row>
                  </Col>

                </FooterRow>
              </Container>
              
            </FooterWrapper>

          )
        }}
      />
    )
  }
}

export default Footer

import React, { Component } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { getGlobal } from '../utility/functions'
import { easingFunctions } from '../utility/easingFunctions'

class Link extends Component {

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  state = {
    currentRef: null
  }

  scrollTransition = (obj) => {

    const {startPos, duration, steps, distance} = obj
    const easing = easingFunctions.easeOutQuint(obj.progress)

    //Counts itterations
    obj.itterations--
    obj.currentPos = startPos + (distance*easing)
    obj.progress = 1 - (obj.itterations/steps)

    //Sets timeout to simulate smooth scrolling
    this.timeout = setTimeout(() => {  
      if (obj.itterations) {

        window.scrollbars.scrollTop(obj.currentPos);
        this.scrollTransition(obj)

      }
    }, (duration/steps))
  }

  scrollToCurrentRef = () => {
    if ( typeof window !== 'undefined' ) {
      const startPos = window.scrollbars.getScrollTop()
      const boundingClientRect = this.state.currentRef.getBoundingClientRect()
      const distance = boundingClientRect.top
      const endPos = startPos + distance
      const duration = 1500 //MS
      const framesPerSec = 60
      const totalSec = duration/1000
      const itterations = Math.floor(totalSec*framesPerSec)

      let obj = {
        progress:     0,
        startPos:     startPos,
        currentPos:   startPos,
        endPos:       endPos,
        distance:     distance - 50,
        duration:     duration,
        framesPerSec: framesPerSec,
        totalSec:     totalSec,
        itterations:  itterations,
        steps:        itterations,
      }
      
      this.scrollTransition(obj)
    }
  }

  onClickHash = event => {
    if ( event ) {
      event.preventDefault()
      let _global = getGlobal()
      const target = event.target.href.split('#')[1]
      const targetRef = _global.scrollToRefs[target]

      if ( targetRef ) {
        this.setState({
          currentRef: targetRef
        }, () => {
          this.scrollToCurrentRef()
        })
      }
    }
  }

  render() {
    const {
      children,
      to,
      target,
      targetBlank,
      className,
      ...rest
    } = this.props

    const winDefined = typeof window !== 'undefined'
    let href = to

    const attrTarget = target || targetBlank ? { target: '_blank' } : {}
    const attrRel = target || targetBlank ? { rel: 'noopener noreferrer' } : {}

    if (winDefined && href && href.includes(window.location.origin)) {
      href = href.replace(new RegExp(window.location.origin, 'g'), '')
    }

    const internal = href && !href.includes('http')
    const hash = href && href.charAt(0) === '#'

    //Use GatsbyLink for internal links
    if (winDefined && internal && !hash) {
      return (
        <GatsbyLink to={href} className={className} {...rest}>
          {children}
        </GatsbyLink>
      )

      //Use regular a-tag for external links or hash-links
    } else {
      return (
        <a href={href} onClick={hash ? this.onClickHash : null} className={className} {...attrTarget} {...attrRel}>
          {children}
        </a>
      )
    }
  }
}

export default Link

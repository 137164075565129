import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'
import Text from './Text'

import Clock from '../../img/clock.svg'
import Marker from '../../img/marker.svg'
import Phone from '../../img/phone.svg'


/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -10px;
`

const IconBox = styled('div')`
	display: flex;
	align-items: center;
	padding: 10px;
	text-align: left;

	* {
		color: ${({white, theme}) => white ? theme.colors.white : theme.colors.black} !important;
	}

	svg {
		height: 20px;
		width: 20px;
		margin-right: 10px;
		fill: ${({white, theme}) => white ? theme.colors.white : theme.colors.black};
	}
`

const Item = styled('div')`
	display: block;
`

const StyledText = styled(Text)`
	font-weight: 700;
	${({theme}) => theme.fontSizes.description}
	line-height: 1.33;
`


/*==============================================================================
  # Component
==============================================================================*/

const ContactCTA = ({ white, ...rest }) => (
	<StaticQuery 
		query={graphql`
      query ContactCTAQuery {
        ...ContactCTA
      }
    `}
    render={data => {

    	const { options } = data.allWordpressAcfOptions.edges[0].node
    	const { openinghours, address, zip, phone } = options
 
    	return (
		    <Wrapper {...rest}>

		    	{openinghours && <IconBox className="icon-box" white={white}>
		    		<Clock />
		    		<Item>
			    		{Array.isArray(openinghours) && openinghours.map((item, i) => 
			    			<StyledText key={i} content={item.day_time} replace={false} />
			    		)}
		    		</Item>
		    	</IconBox>}

		    	{(address || zip) && <IconBox className="icon-box" white={white}>
		    		<Marker />
		    		<Item>
			    		{address && <StyledText content={address} replace={false} />}
			    		{zip && <StyledText content={zip} replace={false} />}
		    		</Item>
		    	</IconBox>}

		    	{phone && <IconBox className="icon-box" white={white}>
		    		<Phone />
		    		<Item>
			    		<StyledText content="Ring oss på" replace={false} />
			    		<StyledText content={phone} />
		    		</Item>
		    	</IconBox>}

		    </Wrapper>
    	)
    }}
	/>
)

export default ContactCTA
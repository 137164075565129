import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Scrollbars } from 'react-custom-scrollbars'
import getScrollbarWidth from 'react-custom-scrollbars/lib/utils/getScrollbarWidth.js'


/*==============================================================================
  # Styles
==============================================================================*/

const Thumb = styled('div')`
  border-radius: 5px;
  background-color: rgba(0,0,0,0.5);
`

const Track = styled('div')`
  z-index: 100;
`

const TrackVertical = styled(Track)`
  top: 2px;
  right: 2px;
  bottom: 2px;
`

const TrackHorizontal = styled(Track)`
  right: 2px;
  left: 2px;
  bottom: 2px;
`


/*==============================================================================
  # Component
==============================================================================*/

class ScrollbarWrapper extends Component {
  
  componentDidMount() {
    document.scrollbar = new CustomEvent('scrollbar', { 
      detail:   "Triggers when react-component scrollbar scrolls",
      bubbles:  true
    })
    window.addEventListener('scroll', this.handleRegularScroll)
    this.handleScrollbarLoad()
  }

  componentWillUnmount() {
  	window.removeEventListener('scroll', this.handleRegularScroll)
  }

  handleScrollbarLoad = () => {
    if ( typeof window !== 'undefined' ) {     
      //Trigger a update for react-custom-scrollbars to prevent native scrollbar to be visible
      let { container, trackVertical, trackHorizontal } = window.scrollbars
      let scrollEle = container.children[0]

      if ( scrollEle ) {
        const scrollbarWidth = getScrollbarWidth()
        scrollEle.style.marginRight = '-'+scrollbarWidth+'px'
        scrollEle.style.marginBottom = '-'+scrollbarWidth+'px'
        
        if ( scrollbarWidth > 0 ) {
          trackVertical.style.display = 'block'
          trackHorizontal.style.display = 'block'
        }
      }
    }
  }

  handleRegularScroll = ( event ) => {
    if ( typeof document !== 'undefined' && document.scrollbar ) {
      document.scrollbar.top = window.scrollY
      document.scrollbar.left = window.scrollX
      document.dispatchEvent( document.scrollbar )
    }
  }

  handleCustomScroll = ( values ) => {
    if ( typeof document !== 'undefined' && document.scrollbar ) {
      document.scrollbar.top = values.scrollTop
      document.scrollbar.left = values.scrollLeft
      document.dispatchEvent( document.scrollbar )
    }
  }

  render () {
    return (
      <Scrollbars
        ref={typeof document !== 'undefined' ? (r) => window.scrollbars = r : null}
        onScrollFrame={this.handleCustomScroll}
        style={{ width: '100vw', height: '100vh' }}
        renderTrackVertical={({...props }) => <TrackVertical {...props} />}
        renderTrackHorizontal={({...props }) => <TrackHorizontal {...props} />}
        renderThumbVertical={({...props }) => <Thumb {...props} />}
        renderThumbHorizontal={({...props }) => <Thumb {...props} />}
        autoHide={true}
        autoHideTimeout={1500}
        autoHideDuration={300}
      >
        {this.props.children}
      </Scrollbars>
    )
  }
} 


export default ScrollbarWrapper
import React from 'react'
import ScrollbarWrapper from './ScrollbarWrapper';
import SiteMetadata from './SiteMetadata'
import Theme from './Theme'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import CookieConsent from './CookieConsent'


/*==============================================================================
  # Component
==============================================================================*/

const TemplateWrapper = ({children}) => (
  <Theme>
    <SiteMetadata />
    <ScrollbarWrapper>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </ScrollbarWrapper>
    <CookieConsent />
  </Theme>
)


export default TemplateWrapper
